import { memo, useEffect, useMemo, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Col,
  Grid,
  Switch
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  updateAlarm,
  deleteAlarm,
  type Alarm as BaseAlarm,
  type AlarmType
} from '@venturi-io/api/src/analytics/alarm'
import { mq } from 'src/utils/style'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import Select from 'src/Input/Select'
import { useApi } from 'src/utils/useApi'
import ActionButtons from './Actions'
import type { Callbacks, Extra, IsOdd, Row } from 'src/Layout/ActionList'

export const useStyles = createStyles((theme, params: IsOdd) => {
  return {
    row: {
      flex: 1,
      width: '100%',
      background: params.odd ? theme.colors.gray[1] : 'transparent',
      alignItems: 'center',
      '&>*>*>label': { // TODO: This is gross
        [mq(theme.breakpoints.sm)]: {
          display: 'none'
        }
      }
    },
    header: {
      borderBottom: `1px solid ${theme.colors.gray[3]}`
    }
  }
})

export interface Alarm extends Omit<BaseAlarm, 'systemOnly'> {}

export interface Actions extends Callbacks {
  onSave: () => void
}

export interface ExtraData extends Extra {
  sensorInstanceId: number
}

type Props = Alarm & Row<Actions, ExtraData> & {
  isNew?: boolean
  isAdding?: boolean
  overrideSave?: (input: Alarm, sensorInstanceId: number) => Promise<void>
  overrideCancel?: () => void
}

const operatorData = [
  { value: '>', label: 'greater than' },
  { value: '<', label: 'less than' },
  { value: '=', label: 'equals' },
  { value: '<=', label: 'less or equal' },
  { value: '>=', label: 'greater or equal' },
  { value: '!=', label: 'not equal' }
]

const alarmTypeData = [
  { value: 'WARNING', label: 'Warning' },
  { value: 'ALARM', label: 'Alarm' }
]

const AlarmRow = memo(({
  actions,
  extra,
  isNew,
  isAdding,
  odd,
  overrideCancel,
  overrideSave,
  ...props
}: Props & Row<Actions>) => {
  const [state, setState] = useState({ ...props })
  const [showConfirm, setShowConfirm] = useState(false)
  const { token } = useUser()
  const { showError } = useNotifications()
  const { classes } = useStyles({ odd })
  const callDelete = useApi(deleteAlarm)
  const callUpdate = useApi(updateAlarm)

  useEffect(() => {
    const data = { ...state }
    if (props.name !== data.name) { data.name = props.name }
    if (props.description !== data.description) { data.description = props.description }
    if (props.enabled !== data.enabled) { data.enabled = props.enabled }
    if (props.manualClear !== data.manualClear) { data.manualClear = props.manualClear }
    if (props.quietMode !== data.quietMode) { data.quietMode = props.quietMode }
    if (props.taskCreationEnabled !== data.taskCreationEnabled) { data.taskCreationEnabled = props.taskCreationEnabled }
    if (props.operator !== data.operator) { data.operator = props.operator }
    if (props.setPoint !== data.setPoint) { data.setPoint = props.setPoint }
    if (props.alarmType !== data.alarmType) { data.alarmType = props.alarmType }
    setState(data)
  }, [
    props.name,
    props.description,
    props.enabled,
    props.operator,
    props.setPoint,
    props.manualClear,
    props.quietMode,
    props.taskCreationEnabled,
    props.alarmType
  ])

  const isDirty = useMemo(() => {
    if (overrideCancel) return true
    if (props.description !== state.description) return true
    if (props.name !== state.name) return true
    if (props.operator !== state.operator) return true
    if (props.enabled !== state.enabled) return true
    if (props.manualClear !== state.manualClear) return true
    if (props.quietMode !== state.quietMode) return true
    if (props.taskCreationEnabled !== state.taskCreationEnabled) return true
    if (props.setPoint !== state.setPoint) return true
    if (props.alarmType !== state.alarmType) return true

    return false
  }, [props, state, overrideCancel])

  type Errors = Record<string, boolean>
  const errors: Errors = {
    name: (state.name ?? '').length < 1,
    setPoint: state.setPoint.toString().length < 1
  }
  const anyErrors = Object
    .keys(errors)
    .map(key => errors[key])
    .some(e => e)

  const update = async (
    input: typeof state,
    sensorInstanceId: number
  ) => {
    if (anyErrors) return

    const {
      alarmId,
      name,
      operator,
      setPoint,
      description,
      enabled,
      manualClear,
      alarmType,
      quietMode,
      taskCreationEnabled
    } = input

    if (!name) {
      return showError(new Error('Invalid alarm details'))
    }

    await callUpdate.fetch({
      alarmId,
      name,
      operator,
      setPoint,
      description: description ?? '',
      enabled,
      alarmType,
      sensorInstanceId,
      manualClear,
      quietMode,
      taskCreationEnabled
    },
    token,
    'Successfully updated alarm')
  }

  const save = () => {
    void (overrideSave ?? update)(state, extra.sensorInstanceId)
      .then(actions.onSave)
  }

  const reset = () => setState({ ...props })
  const cancel = overrideCancel ?? reset

  const sendDelete = async () => {
    await callDelete.fetch({ alarmId: state.alarmId }, token, 'Successfully deleted alarm')
    actions.onSave()
  }

  const deleteBtn = (
    <ActionIcon
      title="Delete"
      size="lg"
      variant="filled"
      color="red"
      loading={callDelete.loading}
      onClick={() => setShowConfirm(true)}
    >
      <FontAwesomeIcon icon={['fas', 'trash']} color="white" size="sm" />
    </ActionIcon>
  )
  const deleteAlarmBtn = !isNew
    ? deleteBtn
    : undefined

  return (
    <>
      <Grid grow className={classes.row} my={0}>
        <Col span={12} sm={2}>
          <TextInput
            size="xs"
            label="Alarm Name"
            value={state.name}
            onChange={ev => setState({ ...state, name: ev.target.value })}
            error={errors.name}
          />
        </Col>
        <Col span={12} sm={1}>
          <Select
            size="xs"
            label="Operator"
            value={state.operator}
            data={operatorData}
            onChange={val => setState({ ...state, operator: val ?? state.operator })}
          />
        </Col>
        <Col span={12} sm={1}>
          <NumberInput
            size="xs"
            label="Set Point"
            value={state.setPoint}
            precision={2}
            step={0.05}
            onChange={val => setState({
              ...state,
              setPoint: parseFloat(val.toString()) ?? 0
            })}
            onBlur={() => {
              const parsed = parseFloat(state.setPoint.toString())
              setState({
                ...state,
                setPoint: parsed
              })
            }}
            error={errors.setPoint}
          />
        </Col>
        <Col span={12} sm={1}>
          <Select
            size="xs"
            label="Alarm Type"
            value={state.alarmType}
            data={alarmTypeData}
            onChange={(val: AlarmType) => setState({ ...state, alarmType: val ?? state.alarmType })}
          />
        </Col>
        <Col span={12} sm={1}>
          <Switch
            size="xs"
            color="primary"
            label="Enabled"
            checked={state.enabled}
            onChange={ev => setState({ ...state, enabled: ev.currentTarget.checked })}
          />
        </Col>
        <Col span={12} sm={1}>
          <Switch
            size="xs"
            color="primary"
            label="Manual clear"
            checked={state.manualClear}
            onChange={ev => setState({ ...state, manualClear: ev.currentTarget.checked })}
          />
        </Col>
        <Col span={12} sm={1}>
          <Switch
            size="xs"
            color="primary"
            label="Quiet mode"
            checked={state.quietMode}
            onChange={ev => setState({ ...state, quietMode: ev.currentTarget.checked })}
          />
        </Col>
        <Col span={12} sm={1}>
          <Switch
            size="xs"
            color="primary"
            label="Task creation"
            checked={state.taskCreationEnabled}
            onChange={ev => setState({ ...state, taskCreationEnabled: ev.currentTarget.checked })}
          />
        </Col>
        <Col span={12} sm={1}>
          <ActionButtons
            hide={!isDirty}
            disabled={anyErrors}
            loading={callUpdate.loading || isAdding}
            extra={deleteAlarmBtn}
            save={save}
            cancel={cancel}
          />
        </Col>
      </Grid>
      <ConfirmModal
        type="delete"
        title="Are you sure?"
        opened={showConfirm}
        question="Do you really want to delete this alarm? This action cannot be undone."
        onClose={() => setShowConfirm(false)}
        onCancel={() => setShowConfirm(false)}
        onConfirm={async () => {
          await sendDelete()
          setShowConfirm(false)
        }}
      />
    </>
  )
}, (prev, next) => {
  if (prev.actions.onSave !== next.actions.onSave) return false
  if (prev.name !== next.name) return false
  if (prev.alarmId !== next.alarmId) return false
  if (prev.description !== next.description) return false
  if (prev.operator !== next.operator) return false
  if (prev.setPoint !== next.setPoint) return false
  if (prev.alarmType !== next.alarmType) return false
  if (prev.quietMode !== next.quietMode) return false
  if (prev.taskCreationEnabled !== next.taskCreationEnabled) return false
  if (prev.odd !== next.odd) return false // TODO: Check this?

  return true
})

export default AlarmRow
