import {
  Box,
  Group,
  Paper,
  Text,
  createStyles
} from '@mantine/core'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { type LiquidType } from '@venturi-io/api/src/config/agent'
import Bar from 'src/Charts/Bar'
import MarkersAnnotationRight from './TankLabels/MarkersAnnotationRight'
import MarkersAnnotationLeft from './TankLabels/MarkersAnnotationLeft'
import MarkersAnnotationMid from './TankLabels/MarkersAnnotationMid'

const useStyles = createStyles(() => ({
  container: {
    margin: '0 auto',
    color: '#212121',
    paddingTop: 16,
    paddingBottom: 16
  },
  barContainer: {
    width: 96,
    position: 'relative',
    svg: {
      overflow: 'visible',
      text: {
        textAnchor: 'start',
        transform: 'translate(94px, -1px)',
        fontWeight: 500,
        fontSize: 14,
        color: '#212121'
      },
      line: {
        transform: 'translateX(10px)'
      },
      'g > g:last-child text': {
        fill: '#666'
      },
      'g > g:last-child line': {
        stroke: '#666 !important'
      }
    },
    rect: {
      width: 64
    }
  },
  labels: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  labelsRight: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  }

}))

interface Props {
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelCapacityLiter: number
  currFuelLevelLiter: number
  fuelLevelLiterAlarms?: Alarm[]
  fuelType?: LiquidType
  withAlarm?: boolean
  isCriticalAlarm?: boolean
}

export default function FuelTank ({
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelCapacityLiter,
  fuelLevelLiterAlarms,
  fuelType,
  withAlarm,
  isCriticalAlarm,
  currFuelLevelLiter
}: Props) {
  const { classes } = useStyles()

  const mapMarkers = [
    {
      axis: 'y',
      value: fuelLevelPercent,
      lineStyle: {
        stroke: 'black',
        strokeWidth: 2
      },
      legend: '◄',
      legendOrientation: 'horizontal'
    },
    {
      axis: 'y',
      value: 95,
      lineStyle: {
        stroke: 'black',
        strokeWidth: 2
      },
      legend: '◄',
      legendOrientation: 'horizontal'
    }
  ]

  const statusColor = isCriticalAlarm
    ? '#E03131'
    : '#FDF054'
  const tankFuelColor = fuelType === 'WATER'
    ? '#fff'
    : statusColor

  return (
    <Box className={classes.container} w="100%">
      <Group position="center" align="flex-end" spacing={16} noWrap h={360}>
        <Box h="100%" className={classes.labels}>
          <MarkersAnnotationLeft
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelLiterAlarms={fuelLevelLiterAlarms ?? []}
            fuelLevelPercent={fuelLevelPercent}
            withAlarm={withAlarm}
            isCriticalAlarm={isCriticalAlarm}
          />
        </Box>
        <Box className={classes.barContainer}>
          <Bar
            height={360}
            data={[
              {
                sensor: 'Fuel Tank',
                value: fuelLevelPercent,
                remainingValue: fuelRemainingPercent
              }
            ]}
            keys={['value', 'remainingValue']}
            colors={[tankFuelColor, '#E6E6E6']}
            indexBy="sensor"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.1}
            minValue={0}
            maxValue={100}
            layout="vertical"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            animate={true}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id, formattedValue }) => (id === 'value' &&
              <Paper px={12} py={8} radius="sm" shadow="sm">
                <Text size={12}>{`${formattedValue}%`}</Text>
              </Paper>
            )}
            enableGridY={false}
            enableGridX={false}
            markers={mapMarkers as []}
          />
          <MarkersAnnotationMid
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelLiterAlarms={fuelLevelLiterAlarms ?? []}
          />
        </Box>
        <Box h="100%" className={classes.labelsRight}>
          <MarkersAnnotationRight
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelPercent={fuelLevelPercent}
            currFuelLevelLiter={currFuelLevelLiter}
          />
        </Box>
      </Group>
    </Box>
  )
}
