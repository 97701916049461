import { ActionIcon, Text, Tooltip } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFuelDetails } from 'src/Assets/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const {
    fuelLevelPercent,
    fuelCapacityLiter,
    fuelLevelFloatVal,
    fuelLevelUnit,
    fuelCapacityUnit,
    fuelLevelLiterUnit
  } = getFuelDetails(sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/tank/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {`${fuelLevelPercent} ${fuelLevelUnit}`}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {`${fuelLevelFloatVal} ${fuelLevelLiterUnit}`}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {`${fuelCapacityLiter} ${fuelCapacityUnit}`}
        </Text>
      </div>
      <div className={classes.col}>
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Link to={`/assets/tanks/${agentId}/transactions`}>
          <Tooltip
            label="View Transactions"
            position="top"
            transitionProps={{
              transition: 'pop',
              duration: 200
            }}
            withArrow
          >
            <ActionIcon color="primary" variant="transparent">
              <FontAwesomeIcon icon={['far', 'receipt']} size="lg" />
            </ActionIcon>
          </Tooltip>
        </Link>
      </div>
    </div>
  )
}
