import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import React, {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { type MapRef, Source, Layer as MapLayer, type LayerProps } from 'react-map-gl'
import { useMantineTheme } from '@mantine/core'
import { generateLocationPoints, generateRawLocationLinePath } from '../../mapbox'
import { useDashLineLayer } from '../../Trips/Layer/DashLineLayer'
import Popup from './Popup'
import type mapboxgl from 'mapbox-gl'

interface Props {
  locations: AgentLocation[]
  mapRef: RefObject<MapRef>
}

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

function Layer ({
  locations,
  mapRef
}: Props) {
  const { colors } = useMantineTheme()
  const {
    dashLineArray,
    start: startDashAnimation,
    stop: stopDashAnimation
  } = useDashLineLayer()
  const [properties, setProperties] = useState<AgentLocation | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const pointSource = useMemo(() => (
    generateLocationPoints(locations)
  ), [locations])

  const lineSource = useMemo(() => (
    generateRawLocationLinePath([...locations].reverse())
  ), [locations])

  const pointLayer: LayerProps = {
    id: 'pointer',
    type: 'circle',
    paint: {
      'circle-color': colors.primary[7],
      'circle-opacity': 1,
      'circle-radius': 6,
      'circle-stroke-color': '#fff',
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 1
    }
  }

  const dashLayer: LayerProps = {
    type: 'line',
    layout: {
      'line-join': 'miter',
      'line-cap': 'butt'
    },
    paint: {
      'line-color': 'white',
      'line-width': 4,
      'line-dasharray': dashLineArray
    }
  }

  useEffect(() => {
    void startDashAnimation()

    return () => {
      void stopDashAnimation()
    }
  }, [locations])

  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  const handleHoverEvent = useCallback((pointer: string) => () => {
    if (map) {
      map.getCanvas().style.cursor = pointer
    }
  }, [map])

  const handlePointClick = useCallback((e: MouseEvent) => {
    if (map) {
      // Copy coordinates array.
      if (e.features) {
        const feature = e.features[0]
        const { properties, geometry } = feature
        if (properties && geometry.type === 'Point') {
          const props = properties as AgentLocation
          const coordinates = geometry.coordinates.slice()
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0]
              ? 360
              : -360
          }

          setProperties(() => {
            setIsPopupOpen(true)

            return {
              ...props,
              geoLocation: {
                latitude: coordinates[1],
                longitude: coordinates[0]
              }
            }
          })
        }
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', ['pointer'], handleHoverEvent(''))
      map.on('mouseenter', ['pointer'], handleHoverEvent('pointer'))
      map.on('click', ['pointer'], handlePointClick)
    }

    return () => {
      if (map) {
        map.off('mouseleave', ['pointer'], handleHoverEvent(''))
        map.off('mouseenter', ['pointer'], handleHoverEvent('pointer'))
        map.off('click', ['pointer'], handlePointClick)
      }
    }
  }, [map])

  return (
    <>
      <Popup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        properties={properties}
        map={map}
      />
      <Source {...lineSource}>
        <MapLayer {...dashLayer} />
      </Source>
      <Source {...pointSource}>
        <MapLayer {...pointLayer} />
      </Source>
    </>
  )
}

export default Layer
