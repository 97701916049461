import { Box, Text } from '@mantine/core'

interface Props {
  label: string
  value: string | number
}

export default function Detail ({ label, value }: Props) {
  return (
    <Box>
      <Text size={8} weight={400} color="gray">
        {label}
      </Text>
      <Text size={12} color="black">
        {value}
      </Text>
    </Box>
  )
}
