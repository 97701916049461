import { type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import type { Role } from 'src/NeedsRole'

export interface MenuItem {
  title: string
  path: string
  role?: Role | Role[]
  icon: FontAwesomeIconProps['icon']
  selector?: string
  children: MenuItem[]
  isHidden?: boolean
}

// TODO: Make this partially dynamic

export const menuList: MenuItem[] = [
  // {
  //   title: 'Home',
  //   path: '/',
  //   icon: ['fas', 'home'],
  //   children: []
  // },
  {
    title: 'Map',
    path: '/',
    icon: ['fas', 'map-marked-alt'],
    selector: 'tour-map__menu',
    children: []
  },
  {
    title: 'Demo',
    path: '/demo',
    icon: ['fas', 'atom'],
    children: [
      {
        title: 'Adaptive UI',
        path: '/demo/adaptive-ui',
        icon: ['fas', 'chart-tree-map'],
        children: []
      },
      {
        title: 'Asset Template',
        path: '/demo/asset-template',
        icon: ['fas', 'flux-capacitor'],
        children: []
      },
      {
        title: 'Components',
        path: '/demo/components',
        icon: ['fas', 'pie-chart'],
        children: []
      }
    ],
    isHidden: true
  },
  {
    title: 'Dashboard',
    path: '/my-dashboard',
    icon: ['fas', 'chart-radar'],
    selector: 'tour-dashboard__menu',
    children: []
  },
  {
    title: 'Assets',
    path: '/assets',
    icon: ['fas', 'flux-capacitor'],
    children: [
      {
        title: 'ATS',
        path: '/assets/ats',
        icon: ['fas', 'chart-radar'],
        selector: 'tour-asset__menu',
        children: []
      },
      // {
      //   title: 'Cameras',
      //   path: '/assets/cameras',
      //   icon: ['far', 'camera'],
      //   children: []
      // },
      {
        title: 'Generator',
        path: '/assets/genset',
        icon: ['fas', 'nfc-magnifying-glass'],
        children: []
      },
      {
        title: 'Generic',
        path: '/assets/generic',
        icon: ['fas', 'gear-complex-code'],
        children: []
      },
      {
        title: 'PDD',
        path: '/assets/duress',
        icon: ['fas', 'siren'],
        children: []
      },
      {
        title: 'Power meter',
        path: '/assets/power-meter',
        icon: ['far', 'utility-pole'],
        children: []
      },
      {
        title: 'Tanks',
        path: '/assets/tank',
        icon: ['fas', 'tank-water'],
        children: []
      },
      {
        title: 'UPS',
        path: '/assets/ups',
        icon: ['fas', 'car-battery'],
        children: []
      },
      {
        title: 'Vehicles',
        path: '/assets/vehicles',
        icon: ['fas', 'car-side'],
        children: []
      }
    ]
  },
  {
    title: 'Sites',
    path: '/sites',
    icon: ['fas', 'earth-asia'],
    children: []
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: ['fas', 'history'],
    selector: 'tour-report__menu',
    children: []
  },
  {
    title: 'Alarm history',
    path: '/alarms',
    icon: ['fas', 'light-emergency-on'],
    children: []
  },
  {
    title: 'Tasks',
    path: '/tasks',
    icon: ['fas', 'list-check'],
    selector: 'tour-asset-task__menu',
    children: []
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: ['fas', 'cog'],
    children: [
      {
        title: 'Agent config',
        path: '/settings/agents',
        icon: ['fas', 'bell'],
        children: []
      },
      {
        title: 'Agent groups',
        path: '/settings/agent-groups',
        icon: ['fas', 'object-group'],
        children: []
      },
      {
        title: 'Alarm actions',
        path: '/settings/alarm-actions',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'wand-magic-sparkles'],
        children: []
      },
      {
        title: 'Geozones',
        path: '/settings/geozones',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'draw-polygon'],
        children: []
      },
      {
        title: 'Organisation',
        path: '/settings/org',
        icon: ['fas', 'city'],
        children: []
      },
      {
        title: 'Sites',
        path: '/settings/sites',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'globe-asia'],
        children: []
      },
      {
        title: 'Users',
        path: '/settings/users',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'users'],
        children: []
      },
      {
        title: 'User groups',
        path: '/settings/user-groups',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'people-group'],
        children: []
      }
    ]
  },
  {
    title: 'Admin',
    path: '/my-admin',
    icon: ['fas', 'toolbox'],
    children: [
      {
        title: 'Agent logs',
        path: '/my-admin/agent/logs',
        icon: ['fas', 'clipboard'],
        children: []
      },
      {
        title: 'Agent templates',
        path: '/my-admin/agent-templates',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'bezier-curve'],
        children: []
      },
      {
        title: 'Advanced routines',
        path: '/my-admin/advanced-routines-demo',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'square'],
        children: []
      },
      {
        title: 'Alarm webhooks',
        path: '/my-admin/alarm-webhooks',
        icon: ['fas', 'bell-on'],
        children: []
      },
      {
        title: 'Camera Events',
        path: '/my-admin/camera-events',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['far', 'camera'],
        children: []
      },
      {
        title: 'Event logs',
        path: '/my-admin/event-logs',
        icon: ['fas', 'clock-rotate-left'],
        children: []
      },
      {
        title: 'Field gateway config',
        path: '/my-admin/field-gateway-config',
        icon: ['fas', 'brackets-curly'],
        children: []
      },
      {
        title: 'Inmarsat logs',
        path: '/my-admin/inmarsat-logs',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'message-dots'],
        children: []
      },
      {
        title: 'Modbus devices',
        path: '/my-admin/modbus-devices',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'microchip'],
        children: []
      },
      {
        title: 'Organisations',
        path: '/my-admin/organisations',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'city'],
        children: []
      },
      {
        title: 'Roles',
        path: '/my-admin/roles',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'user-cog'],
        children: []
      },
      {
        title: 'Scale Factor',
        path: '/my-admin/scale-factor',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'calculator-simple'],
        children: []
      },
      {
        title: 'Sensors',
        path: '/my-admin/sensors',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'sensor'],
        children: []
      },
      {
        title: 'Theme',
        path: '/my-admin/theme',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        icon: ['fas', 'paint-brush-alt'],
        children: []
      }
    ]
  }
]
