import { Stack, Group, Text, createStyles } from '@mantine/core'
import dayjs from 'dayjs'
import { type ReactNode, type Dispatch, type SetStateAction } from 'react'
import { Popup as MapPopup } from 'react-map-gl'
import { dateFormat, uiDateFormat } from 'src/utils/dates'
import { type AgentLocation, type AgentData } from '@venturi-io/api/src/collector/agent'
import type mapboxgl from 'mapbox-gl'

const useStyles = createStyles((theme) => ({
  pin: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    border: '1px solid #fff',
    borderRadius: '100%',
    width: 12,
    height: 12,
    color: 'red',
    fontWeight: 500,
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -11,
      top: -11,
      width: 34,
      height: 34
    }
  },
  special: {
    width: '30px !important',
    height: '30px !important',
    color: 'black',
    backgroundColor: '#fff !important',
    boxShadow: `0 6px 7px ${theme.colors.gray[6]}`,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -15,
      top: -15,
      width: 34,
      height: 34
    }
  },
  hasDirection: {
    width: 23,
    height: 23,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -6,
      top: -6,
      width: 34,
      height: 34
    }
  },
  popup: {
    zIndex: 2
  },
  direction: {
    backgroundColor: theme.colors.gray[8],
    borderRadius: '10px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export interface DataProps {
  coordinates: number[]
  timestamp: string
  position: number
  agentId: number
  pathId: string
  color: string
}

export interface PositionDetails {
  details?: AgentData
  coordinates?: number[]
}

interface PopupProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  properties: AgentLocation | null
  isOpen: boolean
  map: mapboxgl.Map | null
}

interface LabelValueProps {
  label: string
  value: string | number
  icon?: ReactNode
}

const LabelValue = ({ label, value, icon }: LabelValueProps) => (
  <Group position="apart">
    <Text size="xs" color="dimmed">
      {label}
      :
    </Text>
    <Group spacing={4}>
      <Text size="xs" weight="bold">{value}</Text>
      {icon && icon}
    </Group>
  </Group>
)

function Popup ({
  isOpen,
  setIsOpen,
  properties
}: PopupProps) {
  const { classes } = useStyles()
  if (!properties) return null

  const {
    timestamp,
    geoLocation: {
      longitude,
      latitude
    }
  } = properties

  return isOpen
    ? (
      <MapPopup
        key={timestamp}
        closeButton
        closeOnClick={false}
        latitude={latitude ?? 0}
        longitude={longitude ?? 0}
        anchor="bottom"
        className={classes.popup}
        onClose={() => setIsOpen(false)}
        style={{
          zIndex: 95,
          maxWidth: '300px'
        }}
      >
        <Stack
          spacing={4}
          pt="md"
          pb="xs"
          px="xs"
        >
          <Text weight="bold">
            {dayjs(timestamp, dateFormat).format(uiDateFormat)}
          </Text>
          <LabelValue
            label="Longitude"
            value={longitude}
          />
          <LabelValue
            label="Latitude"
            value={latitude}
          />
        </Stack>
      </MapPopup>
      )
    : null
}

export default Popup
