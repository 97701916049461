import { createStyles, Box, Text } from '@mantine/core'
import { IconWand } from '@tabler/icons-react'

const useStyles = createStyles(() => ({
  container: {
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    borderRadius: '8px 8px 0 0'
  }
}))

export default function Header () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <IconWand size={20} color="gray" />
      <Text
        ml={8}
        size={16}
        weight={700}
        color="gray.8"
      >
        Geozone Wizard
      </Text>
    </Box>
  )
}
