import { useCallback, useEffect, useState } from 'react'
import { Col, Grid, Group } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  type ComplianceSummary,
  type IncidentsSummary,
  getIncidentsSummary
} from '@venturi-io/api/src/config/alarmHistory'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormat } from 'src/utils/dates'
import { observer } from 'mobx-react-lite'
import { downloadCSV } from 'src/utils/files'
import { type AlarmHistoryState } from 'src/UIState'
import { type DateRange } from 'src/utils/dates'
import { useUser } from 'src/UserContext'
import ActionButton from 'src/ActionButton'
import Paper from 'src/Layout/Paper'
import Select from 'src/Input/Select'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import SelectAgent from 'src/Input/Select/SelectAgent'
import { type ReportFileType } from 'src/Reports'
import DateRangePicker from 'src/Input/DateRangePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IncidentSummary from './IncidentSummary'
import Compliance from './Compliance'
import ExportToPdf from './ExportToPdf'

const ClearIcon = () => <FontAwesomeIcon icon={['fas', 'close']} size="sm" />

export interface ChildProps {
  agentId: string
  sensorInstanceId: string
  dateRange: DateRange
  setAlarmHistory?: (data: IncidentsSummary['items']) => void
  setComplianceSummary?: (data: ComplianceSummary) => void
}

export interface AlarmItem {
  incident_id: number
  agent_name: string
  sensor_name: string
  detected: string
  resolved: string
  alarm: string
}

interface Props {
  state: AlarmHistoryState
}

function AlarmHistoryPage ({ state }: Props) {
  const agent = useApi(getAgentDetails)
  const incidentsSummaryReport = usePaginatedApi(getIncidentsSummary)
  const { sensorInstanceId, dateRange } = state
  const { token } = useUser()
  const [agentId, setAgentId] = useState<string | null>(null)
  const agentName = agent.data.mapOrDefault(({ agentName }) => agentName, '')
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [alarmHistory, setAlarmHistory] = useState<AlarmItem[]>([])
  const [complianceSummary, setComplianceSummary] = useState<ComplianceSummary | null>(null)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [value, setValue] = useState<DateRange>([
    dayjs(new Date()).subtract(7, 'days').toDate(),
    dayjs(new Date()).toDate()
  ])

  const loadAgentDetails = () => {
    if (agentId === null || agentId === '') {
      return
    }

    void agent.fetch({ agentId: parseInt(agentId) }, token)
  }

  useEffect(() => {
    loadAgentDetails()
  }, [agentId])

  const selectSensors = agent
    .data
    .map(({ agentName, sensors }) => (
      sensors.map(({ sensorInstanceId, name }) => ({
        value: sensorInstanceId.toString(),
        label: name,
        group: agentName
      }))
    ))
    .mapOrDefault(r => r, [])

  const sensorName = selectSensors.find(({ value: id }) => id === sensorInstanceId)?.label ?? ''

  useEffect(() => {
    const result: DateRange = [...dateRange]
    const firstIsNull = value[0] === null
    const secondIsNull = value[1] === null
    const isSameFirstDate = dayjs(value[0]).diff(dateRange[0], 'days') === 0
    const isSameSecondDate = dayjs(value[1]).diff(dateRange[1], 'days') === 0

    if (isSameFirstDate && isSameSecondDate) return

    if (!firstIsNull) {
      result[0] = value[0]
    }

    if (!secondIsNull) {
      result[1] = value[1]
    }

    state.dateRange = result
  }, [value])

  const onSelectAgent = useCallback((agentId: string | null) => {
    state.sensorInstanceId = null
    setAgentId(agentId)
  }, [])

  const onSelectSensorInstance = useCallback((item: string) => {
    state.sensorInstanceId = item
  }, [])

  const handleDownloadFile = useCallback((fileType: ReportFileType) => {
    setReportType(fileType)

    void incidentsSummaryReport.fetch({
      sensorInstanceId: sensorInstanceId ?? '',
      startTime: dayjs(dateRange[0]).format(`${dateFormat}`),
      endTime: dayjs(dateRange[1]).format(`${dateFormat}`),
      page: 1,
      size: 99999999
    }, token)
  }, [sensorInstanceId, dateRange])

  const childProps = {
    agentId: agentId ?? '',
    sensorInstanceId: sensorInstanceId ?? '',
    dateRange
  }

  useEffect(() => {
    // generate and download alarm history report
    incidentsSummaryReport.data.ifJust(({ items }) => {
      const data: AlarmItem[] = items.map(({
        incidentId,
        name,
        operator,
        setPoint,
        detected,
        cleared
      }) => ({
        incident_id: incidentId,
        agent_name: agentName,
        sensor_name: sensorName,
        detected: dayjs(detected).format(uiDateFormat),
        resolved: cleared
          ? dayjs(cleared).format(uiDateFormat)
          : 'Active',
        alarm: `${operator}${setPoint} (${name})`
      }))

      if (reportType === 'CSV') {
        downloadCSV(
          data as unknown as Array<Record<string, unknown>>,
          `${agentName} (${sensorName}) - Alarm History Report`
        )
      }

      if (reportType === 'PDF') {
        setAlarmHistory(data)
        toggle()
      }
    })
  }, [incidentsSummaryReport.data])

  useEffect(() => {
    return () => {
      state.sensorInstanceId = null
    }
  }, [])

  const onClearAgent = useCallback(() => {
    setAgentId('')
    state.sensorInstanceId = null
  }, [])

  const onClearSensorInstance = useCallback(() => {
    state.sensorInstanceId = null
  }, [])

  return (
    <>
      <Paper my={0}>
        <NeedsRole role={adminRoles}>
          <Group spacing={3} position="right">
            <ActionButton
              label="Download as CSV"
              icon="file-csv"
              iconSize="1x"
              actionVariant="transparent"
              onClick={() => handleDownloadFile('CSV')}
              actionDisabled={!sensorInstanceId}
            />
            <ActionButton
              label="Download as PDF"
              icon="file-pdf"
              iconSize="1x"
              actionVariant="transparent"
              onClick={() => handleDownloadFile('PDF')}
              actionDisabled={!sensorInstanceId}
            />
          </Group>
        </NeedsRole>
        <Grid mt={2}>
          <Col span={12} sm={4}>
            <SelectAgent
              setExternalAgentId={onSelectAgent}
              value={agentId}
              searchable
              rightSection={<ClearIcon />}
              rightSectionProps={{
                onClick: onClearAgent
              }}
            />
          </Col>
          <Col span={12} sm={4}>
            <Select
              searchable
              disabled={!agentId}
              placeholder="Choose a sensor"
              value={sensorInstanceId}
              data={selectSensors}
              onChange={onSelectSensorInstance}
              rightSection={<ClearIcon />}
              rightSectionProps={{ onClick: onClearSensorInstance }}
            />
          </Col>
          <Col span={12} sm={4}>
            <DateRangePicker
              placeholder="Choose date range"
              value={value}
              onChange={setValue}
              clearable={false}
              disabled={!agentId}
              maxDate={dayjs().toDate()}
            />
          </Col>
        </Grid>
      </Paper>
      <Compliance {...childProps} setComplianceSummary={setComplianceSummary} />
      <IncidentSummary {...childProps} />
      {sensorInstanceId && complianceSummary && (
        <ExportToPdf
          agentName={agentName}
          sensorName={sensorName}
          complianceSummary={complianceSummary}
          data={alarmHistory}
          dateRange={dateRange}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
        />
      )}
    </>
  )
}

const AlarmHistory = observer<Props>(AlarmHistoryPage)

export default AlarmHistory
