import dayjs, { type Dayjs } from 'dayjs'

export type DateRange = [Date | null, Date | null]

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
export const uiDateOnly = 'DD/MM/YYYY'
export const uiTimeOnly = 'HH:mm:ss'
export const uiDateFormat = `${uiDateOnly} ${uiTimeOnly}`
export const uiDateFormatRegular = `${uiDateOnly} h:mm A`
export const uiDateOnlyFormatUniversal = 'DD MMM YYYY'
export const uiDateFormatUniversal = `${uiDateOnlyFormatUniversal} h:mm A`

export function giveOffset () {
  const date = new Date()
  let offset = date.getTimezoneOffset()
  const isNegative = Math.sign(offset) === 1

  if (!isNegative) {
    offset = offset * -1
  }

  const rawHours = Math.floor(offset / 60)
  const rawMinutes = Math.floor(offset % 60)

  const sign = isNegative ? '-' : '+'
  const hours = rawHours < 10 ? `0${rawHours}` : rawHours
  const minutes = rawMinutes < 10 ? `0${rawMinutes}` : rawMinutes

  return `${sign}${hours}:${minutes}`
}

export function msToDhms (ms: number) {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000))
  const daysms = ms % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysms / (60 * 60 * 1000))
  const hoursms = ms % (60 * 60 * 1000)
  const minutes = Math.floor(hoursms / (60 * 1000))
  const minutesms = ms % (60 * 1000)
  const sec = Math.floor(minutesms / 1000)

  if (days > 0) {
    return `${days}d ${hours}hr ${minutes}min ${sec}s`
  }

  if (hours > 0) {
    return `${hours}hr ${minutes}min ${sec}s`
  }

  if (minutes > 0) {
    return `${minutes}min ${sec}s`
  }

  return `${sec}s`
}

export const toUtc = (value: string | Dayjs) => {
  let date = value
  if (typeof date === 'string') {
    date = dayjs(value, dateFormat)
  }
  return date.utc().format(dateFormat)
}

export const userMachineTz = Intl.DateTimeFormat().resolvedOptions().timeZone

export function secondsToHoursMins (seconds: number | undefined) {
  if (typeof seconds !== 'number' || seconds <= 0) return '-'

  const hours = Math.floor(seconds / 3600)
  const hoursSec = seconds % (60 * 60)
  const minutes = Math.floor(hoursSec / 60)

  if (hours > 0) {
    return `${hours}hr(s) ${minutes}min(s)`
  }

  return `${minutes}min(s)`
}

interface FixedDateRange {
  startTime: string | null
  endTime: string | null
}

export const toFixedDateRange = (dateRange: DateRange): FixedDateRange => ({
  startTime: dateRange[0] !== null
    ? `${dayjs(dateRange[0]).format('YYYY-MM-DD')}T00:00:00${dayjs(dateRange[0]).format('Z')}`
    : null,
  endTime: dateRange[1] !== null
    ? `${dayjs(dateRange[1]).format('YYYY-MM-DD')}T23:59:59${dayjs(dateRange[1]).format('Z')}`
    : null
})
