import {
  createStyles,
  ActionIcon,
  Button,
  Box,
  Group,
  Tooltip
} from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'

const useStyles = createStyles(() => ({
  container: {
    background: '#FFF',
    padding: '16px 20px',
    width: 320,
    borderTop: '.5px solid #E2E1E1'
  }
}))

interface Props {
  currentStep: number
  lastStep: number
  prevStep: () => void
  nextStep: () => void
  onDelete: () => void
}

export default function Controls ({
  currentStep,
  lastStep,
  prevStep,
  nextStep,
  onDelete
}: Props) {
  const { classes } = useStyles()
  const isLastStep = currentStep === lastStep

  return (
    <Box className={classes.container}>
      <Group position="apart" align="center">
        <Tooltip
          label="Delete drawn geozone"
          position="left"
          transitionProps={{
            transition: 'fade',
            duration: 200
          }}
          withArrow
        >
          <ActionIcon
            variant="subtle"
            color="gray.6"
            onClick={onDelete}
          >
            <IconTrash size={24} />
          </ActionIcon>
        </Tooltip>
        <Group
          position="center"
          align="center"
          spacing={16}
        >
          {currentStep !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          <Button onClick={nextStep}>
            {isLastStep
              ? 'Create'
              : 'Next'}
          </Button>
        </Group>
      </Group>
    </Box>
  )
}
