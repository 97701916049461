import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from '@mantine/form'
import {
  Box,
  Col,
  Grid,
  Group,
  Stack,
  Text
} from '@mantine/core'
import TextInput from 'src/Input/TextInput'
import ActionButton from 'src/ActionButton'

type Value = Record<string, string>

interface Item {
  name: string
  value: string
}

interface FormProps {
  data: Item[]
}

interface Props {
  initialValue?: Value
  onChange: (value: Value) => void
  setErrorMessage: (value: string | null) => void
}

export const defaultItem: Item = {
  name: '',
  value: ''
}

export default function MetadataInput ({
  initialValue,
  onChange,
  setErrorMessage
}: Props) {
  const form = useForm<FormProps>({
    initialValues: {
      data: []
    }
  })

  const hasDuplicatedInputs = useMemo(() => {
    const inputKeys = form.values.data
      .filter(({ name }) => name.length > 0)
      .map(({ name }) => name)
    const uniqueInputKeys = new Set(inputKeys)

    return inputKeys.length !== uniqueInputKeys.size
  }, [form.values.data])

  const handleAddItem = () => {
    form.insertListItem('data', { ...defaultItem })
  }

  const handleValueChange = useCallback(() => {
    const result = form.validate()

    if (!result.hasErrors) {
      if (hasDuplicatedInputs) {
        setErrorMessage('** Duplicated inputs are not allowed **')
      } else {
        const metadata = form.values.data.reduce((data: Value, {
          name,
          value
        }) => {
          data[name] = value

          return data
        }, {})

        setErrorMessage(null)
        onChange(metadata)
      }
    }
  }, [form.values.data, onChange])

  useEffect(() => {
    handleValueChange()
  }, [form.values.data])

  useEffect(() => {
    if (initialValue) {
      const transformedData: Item[] = Object.entries(initialValue).map(([name, value]) => ({
        name,
        value
      }))

      form.setFieldValue('data', transformedData)
    }
  }, [initialValue])

  const items = form.values.data.map((_, index) => (
    <Grid key={index} gutter={4} gutterSm={12}>
      <Col span={12} sm={6}>
        <Group align="flex-end" spacing={4}>
          <TextInput
            required
            label="Name"
            sx={{
              flexGrow: 1
            }}
            onKeyUp={() => {
              handleValueChange()
            }}
            {...form.getInputProps(`data.${index}.name`)}
          />
          <Box mb={1}>
            <ActionButton
              label="Remove item"
              actionVariant="default"
              actionSize="lg"
              icon="remove"
              iconType="fas"
              iconColor="gray"
              iconSize="sm"
              onClick={() => {
                form.removeListItem('data', index)
                handleValueChange()
              }}
            />
          </Box>
        </Group>
      </Col>
      <Col span={12} sm={6}>
        <TextInput
          required
          label="Value"
          onKeyUp={() => {
            handleValueChange()
          }}
          {...form.getInputProps(`data.${index}.value`)}
        />
      </Col>
    </Grid>
  ))

  return (
    <Grid grow>
      <Col span={12}>
        <Group spacing={8}>
          <Text size={12}>Metadata</Text>
          <ActionButton
            label="Add item"
            icon="plus"
            iconType="fas"
            onClick={handleAddItem}
            actionColor="blue"
            actionVariant="filled"
            actionSize="xs"
            iconColor="white"
            iconSize="xs"
          />
        </Group>
        <Col span={12}>
          <Stack spacing={16}>
            {items}
          </Stack>
        </Col>
      </Col>
    </Grid>
  )
}
