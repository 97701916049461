import { z } from 'zod'
import { type PaginatedRequest, paginated } from '../shared'
import { createStatelessApi } from '../configure'

const defaultAlarm = z.object({
  siteId: z.optional(z.number()),
  agentId: z.optional(z.number()),
  siteName: z.optional(z.string()),
  agentName: z.optional(z.string()),
  incidentId: z.optional(z.number()),
  stringValue: z.optional(z.string()),
  sensorInstanceId: z.optional(z.number()),
  sensorInstanceName: z.optional(z.string())
})

const speedAlarm = z.object({
  id: z.optional(z.number()),
  orgId: z.optional(z.number()),
  speed: z.optional(z.number()),
  tripId: z.optional(z.number()),
  agentId: z.optional(z.number()),
  agentName: z.optional(z.string()),
  geoZoneId: z.optional(z.number()),
  orgUserId: z.optional(z.nullable(z.number())),
  speedLimit: z.optional(z.number()),
  geoZoneName: z.optional(z.string()),
  userLastName: z.optional(z.nullable(z.string())),
  speedDateTime: z.optional(z.string()),
  tripStartTime: z.optional(z.string()),
  userFirstName: z.optional(z.nullable(z.string())),
  agentLocationId: z.optional(z.number())
})

const geoZoneAlarm = z.object({
  id: z.optional(z.number()),
  time: z.optional(z.string()),
  agentId: z.optional(z.number()),
  agentName: z.optional(z.string()),
  geoZoneId: z.optional(z.number()),
  geoZoneName: z.optional(z.string()),
  geoZoneRuleName: z.optional(z.string()),
  agentLocationPoint: z.optional(z.object({
    latitude: z.number(),
    longitude: z.number()
  }))
})

const taskAlarm = z.object({
  id: z.optional(z.number()),
  agent: z.optional(z.object({
    agentId: z.number(),
    agentName: z.string(),
    assetType: z.string()
  })),
  status: z.optional(z.string()),
  dueDate: z.optional(z.string()),
  priority: z.optional(z.string()),
  taskType: z.optional(z.string()),
  taskGroup: z.optional(z.string())
})

export const alarmTypes = [
  'DEFAULT_ALARM',
  'GEO_ZONE_ALARM',
  'SPEED_ALARM',
  'TASK'
] as const

export type AlarmTypes = typeof alarmTypes[number]

export const alarm = z.object({
  id: z.number(),
  type: z.string(),
  timestamp: z.string(),
  quietMode: z.boolean(),
  data: defaultAlarm
    .merge(speedAlarm)
    .merge(taskAlarm)
    .merge(geoZoneAlarm)
    .merge(taskAlarm)
})

export type DefaultDataItem = z.infer<typeof defaultAlarm>

export type SpeedDataItem = z.infer<typeof speedAlarm>

export type GeozoneDataItem = z.infer<typeof geoZoneAlarm>

export type TaskDataItem = z.infer<typeof taskAlarm>

export type Alarm = z.infer<typeof alarm>

export const notifications = paginated.extend({
  items: z.array(alarm)
})

export const getAllNotifications = createStatelessApi<PaginatedRequest, typeof notifications>(
  'analytics',
  '/notifications',
  { method: 'GET', type: 'path' },
  notifications
)
