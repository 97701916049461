import {
  createStyles,
  Divider,
  ScrollArea,
  Stack,
  Switch,
  Text,
  Timeline
} from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconAffiliate,
  IconAutomation,
  IconPolygon,
  IconListDetails,
  IconSettings2
} from '@tabler/icons-react'
import TextInput from 'src/Input/TextInput'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import SelectRule from 'src/Input/Select/SelectRule'
import MultiSelectAction from 'src/Input/MultiSelect/MultiSelectAction'
import { useEffect } from 'react'
import { isEmpty } from 'src/utils/objects'
import ItemDescription from './ItemDescription'
import ItemContentWrapper from './ItemContentWrapper'

const useStyles = createStyles(() => ({
  container: {
    background: '#FFF',
    padding: 20,
    width: 320,
    height: 320
  }
}))

export interface FormProps {
  name: string
  description?: string
  agentGroupIds?: number[]
  geoZoneRuleId?: number
  geoZoneActionIds?: number[]
  hiddenOnMap: boolean
}

export const formInitialValues: FormProps = {
  name: '',
  description: '',
  agentGroupIds: [],
  geoZoneRuleId: -1,
  geoZoneActionIds: [],
  hiddenOnMap: false
}

interface Props {
  step: number
  setFormData: (data: FormProps) => void
  setHasError: (hasError: boolean) => void
}

export default function Procedure ({
  step,
  setFormData,
  setHasError
}: Props) {
  const { classes } = useStyles()
  const iconSize = 16
  const form = useForm<FormProps>({
    initialValues: formInitialValues,
    validate: {
      name: value => value.length === 0
        ? 'Geozone name is required'
        : null
    }
  })

  useEffect(() => {
    // Input validations
    if (step === 1) {
      form.validateField('name')
    }
  }, [step])

  useEffect(() => {
    setFormData(form.values)
  }, [form.values])

  useEffect(() => {
    setHasError(!isEmpty(form.errors))
  }, [form.errors])

  return (
    <ScrollArea
      className={classes.container}
      type="hover"
      scrollbarSize={5}
    >
      <Timeline
        styles={{
          itemTitle: {
            fontSize: 12
          },
          itemBullet: {
            transition: 'all .5s ease'
          }
        }}
        active={step}
        color="teal.6"
        radius="lg"
        bulletSize={32}
        lineWidth={2}
      >
        <Timeline.Item
          title="Name the Geozone"
          bullet={<IconPolygon size={iconSize} />}
        >
          <ItemDescription value="Add some context" />
          <ItemContentWrapper active={step === 0}>
            <Stack spacing={8}>
              <TextInput
                withAsterisk
                placeholder="Name"
                {...form.getInputProps('name')}
              />
              <TextInput
                placeholder="Description"
                {...form.getInputProps('description')}
              />
            </Stack>
          </ItemContentWrapper>
        </Timeline.Item>
        <Timeline.Item
          title="Select Asset Groups"
          bullet={<IconAffiliate size={iconSize} />}
        >
          <ItemDescription value="Limiting the scope to these Assets" />
          <ItemContentWrapper active={step === 1}>
            <MultiSelectAgentGroup
              value={form.values?.agentGroupIds?.map(id => id.toString())}
              onChange={ids => (
                form.setFieldValue('agentGroupIds', ids.map(id => Number(id)))
              )}
              searchable
            />
          </ItemContentWrapper>
        </Timeline.Item>
        <Timeline.Item
          title="Configure Rule & Actions"
          bullet={<IconAutomation size={iconSize} />}
        >
          <ItemDescription value="Set up rule with automated actions" />
          <ItemContentWrapper active={step === 2}>
            <Stack spacing={15}>
              <SelectRule
                value={form.values?.geoZoneRuleId?.toString()}
                existingRules={[]}
                setExternalRule={ruleId => {
                  if (ruleId) {
                    form.setFieldValue('geoZoneRuleId', Number(ruleId))
                  }
                }}
                error={form.errors.geoZoneRuleId}
                searchable
              />
              <Stack mx={6} spacing={5}>
                <Divider label="Actions" />
                <MultiSelectAction
                  value={form.values?.geoZoneActionIds?.map(id => id.toString())}
                  onChange={ids => {
                    form.setFieldValue('geoZoneActionIds', ids.map(id => Number(id)))
                  }}
                  disabled={form.values.geoZoneRuleId === -1}
                  searchable
                />
              </Stack>
            </Stack>
          </ItemContentWrapper>
        </Timeline.Item>
        <Timeline.Item
          title="Settings"
          bullet={<IconSettings2 size={iconSize} />}
        >
          <ItemDescription value="Configure additional features" />
          <ItemContentWrapper active={step === 3}>
            <Switch
              label="Hide Geozone on Map"
              color="primary"
              size="sm"
              checked={form.values.hiddenOnMap}
              onChange={event => {
                form.setFieldValue('hiddenOnMap', event.currentTarget.checked)
              }}
            />
          </ItemContentWrapper>
        </Timeline.Item>
        <Timeline.Item
          title="Summary"
          bullet={<IconListDetails size={iconSize} />}
        >
          <ItemContentWrapper active={step === 4}>
            <Stack spacing="sm">
              <TextInput
                withAsterisk
                label="Name"
                value={form.values.name}
                disabled
              />
              <TextInput
                label="Description"
                value={form.values.description}
                disabled
              />
              <MultiSelectAgentGroup
                label="Asset Groups"
                value={form.values?.agentGroupIds?.map(id => id.toString())}
                disabled
              />
              <Stack spacing="xs">
                <SelectRule
                  label="Rule"
                  value={form.values?.geoZoneRuleId?.toString()}
                  existingRules={[]}
                  setExternalRule={() => {}}
                  disabled
                />
                <Stack spacing={5}>
                  <Divider label="Actions" />
                  <MultiSelectAction
                    value={form.values?.geoZoneActionIds?.map(id => id.toString())}
                    disabled
                  />
                </Stack>
              </Stack>
              <Stack spacing="xs">
                <Text size="xs">Settings</Text>
                <Switch
                  label="Hide Geozone on Map"
                  color="primary"
                  size="sm"
                  checked={form.values.hiddenOnMap}
                />
              </Stack>
            </Stack>
          </ItemContentWrapper>
        </Timeline.Item>
      </Timeline>
    </ScrollArea>
  )
}
