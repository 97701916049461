import { useCallback, useState } from 'react'
import {
  createStyles,
  Collapse,
  Divider,
  Group,
  Paper,
  Stack
} from '@mantine/core'
import { IconWand } from '@tabler/icons-react'
import { type GeoZoneAction } from '@venturi-io/api/src/config/geoZoneAction'
import TextTooltip from 'src/Layout/TextTooltip'
import Detail from '../Detail'
import Actions from './Actions'

const useStyles = createStyles(theme => ({
  container: {
    cursor: 'pointer',
    background: '#FFF',
    '&:hover': {
      background: theme.colors.gray[0]
    },
    overflowX: 'hidden',
    transition: 'all .3s ease'
  }
}))

interface Props {
  action: GeoZoneAction
  onSelect: (action: GeoZoneAction) => void
  onClickEdit?: (actionId: GeoZoneAction['geoZoneActionId']) => void
  onSuccessDelete?: (actionId: GeoZoneAction['geoZoneActionId']) => void
}

export default function Item ({
  action,
  onSelect,
  onClickEdit,
  onSuccessDelete
}: Props) {
  const { classes } = useStyles()
  const [showDetails, setShowDetails] = useState(false)
  const {
    geoZoneActionId,
    name,
    action: geoZoneAction,
    description,
    userGroup
  } = action

  const handleClick = useCallback(() => {
    onSelect(action)
    setShowDetails(!showDetails)
  }, [action, showDetails])

  return (
    <Paper
      key={geoZoneActionId}
      className={classes.container}
      p={16}
      shadow="xs"
      radius={8}
      onClick={handleClick}
    >
      <Group position="apart" align="center">
        <Group spacing={8}>
          <IconWand
            size={20}
            stroke={1.5}
            color="#2998F0"
          />
          <TextTooltip
            value={name}
            size={12}
            maxLength={25}
          />
        </Group>
        <Actions
          actionId={geoZoneActionId}
          actionName={name}
          onClickEdit={onClickEdit}
          onSuccessDelete={onSuccessDelete}
        />
      </Group>
      <Collapse in={showDetails}>
        <Divider mt={16} mb={8} variant="dotted" />
        <Stack p={4} spacing={8}>
          <Detail label="Action" value={geoZoneAction} />
          {description && (
            <Detail label="Description" value={description} />
          )}
          <Detail label="User Group" value={userGroup.name ?? '-'} />
        </Stack>
      </Collapse>
    </Paper>
  )
}
