import React, { memo, useCallback, useRef } from 'react'
import { Stack, useMantineTheme, Text as MText, Group } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis'
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape'
import { MarkerCircle } from '@visx/marker'
import { LinearGradient } from '@visx/gradient'
import { scaleTime, scaleLinear } from '@visx/scale'
import { localPoint } from '@visx/event'
import { Text } from '@visx/text'
import {
  defaultStyles as tooltipStyles,
  Tooltip,
  TooltipWithBounds,
  withTooltip
} from '@visx/tooltip'
import { type WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { bisector, extent, max } from 'd3-array'
import { round } from 'src/utils/math'
import { truncateWithEllipsis } from 'src/utils/strings'
import {
  curves,
  type DataPoint,
  type CurveType,
  type ColorGradient,
  type Margin
} from './shared'
import { formatDateTime } from './utils'

export interface Theme {
  titleColor?: React.CSSProperties['color']
  areaColor?: ColorGradient
  auxColor?: ColorGradient
  backgroundColor?: ColorGradient
  axisColor?: React.CSSProperties['color']
  textColor?: React.CSSProperties['color']
  markerColor?: React.CSSProperties['color']
  markerStrokeColor?: React.CSSProperties['color']
}

interface Styles {
  width: number
  height: number
  margin: Margin
  theme?: Theme
  curveType?: CurveType
  withoutTitle?: boolean
  withoutRecentValue?: boolean
  withoutXAxis?: boolean
  withoutYAxis?: boolean
  withoutTooltip?: boolean
}

export const defaultStyles: Styles = {
  width: 500,
  height: 300,
  margin: {
    top: 60,
    right: 0,
    bottom: 0,
    left: 0
  },
  curveType: 'linear',
  withoutTitle: false,
  withoutRecentValue: false,
  withoutXAxis: false,
  withoutYAxis: false,
  withoutTooltip: false
}

export interface Props {
  id: string
  title: string
  group?: string
  data: DataPoint[]
  auxData?: DataPoint[]
  styles: Styles
}

interface CirclePosition {
  left: number
  top: number
}

interface Circles {
  c1: CirclePosition
  c2: CirclePosition
}

interface DataPointWithAux {
  data: DataPoint
  auxData: DataPoint
  circles: Circles
}

type TooltipData = DataPointWithAux

const getValueX = (dataPoint: DataPoint) => new Date(dataPoint.x)
const getValueY = (dataPoint: DataPoint) => dataPoint.y ?? 0
const bisectDate = bisector<DataPoint, Date>(getValueX).left

export default memo(withTooltip<Props, TooltipData>(
  ({
    data,
    auxData,
    title,
    group,
    styles = defaultStyles,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0
  }: Props & WithTooltipProvidedProps<TooltipData>) => {
    const chartId = randomId()
    const svgRef = useRef<SVGSVGElement>(null)
    const { colors } = useMantineTheme()
    const {
      width,
      height,
      margin,
      theme,
      curveType,
      withoutTitle,
      withoutRecentValue,
      withoutXAxis,
      withoutYAxis,
      withoutTooltip
    } = styles

    // set default theme
    const titleColor = theme?.titleColor ?? colors.gray[0]
    const areaColor: ColorGradient = theme?.areaColor ?? {
      primary: colors.gray[0],
      secondary: colors.gray[8]
    }
    const auxColor: ColorGradient = theme?.auxColor ?? {
      primary: colors.gray[0],
      secondary: colors.gray[8]
    }
    const backgroundColor: ColorGradient = theme?.backgroundColor ?? {
      primary: colors.primary[4],
      secondary: colors.primary[6]
    }
    const curve = curves[curveType ?? 'linear']
    const axisColor = theme?.axisColor ?? colors.gray[1]
    const textColor = theme?.textColor ?? colors.gray[0]
    const markerColor = theme?.markerColor ?? colors.primary[5]
    const markerStrokeColor = theme?.markerStrokeColor ?? colors.primary[0]
    const backgroundGradientId = `${chartId}-background-gradient`
    const linePathGradientId = `${chartId}-linepath-gradient`
    const auxLinePathGradientId = `${chartId}-aux-linepath-gradient`
    const areaGradientId = `${chartId}-area-gradient`
    const markerCircleId = `${chartId}-marker-circle`
    const auxMarkerCircleId = `${chartId}-aux-marker-circle`
    const containerSize = width + height
    const innerWidth = width - (margin.left + margin.right)
    const innerHeight = height - (margin.top + margin.bottom)
    const axisFontSize = `${containerSize / 110}px`

    // Left Axis
    const dataPoints = data.sort((a, b) => (a.x > b.x ? 1 : -1))
    const recentValue = dataPoints.length
      ? dataPoints[dataPoints.length - 1].y ?? 0
      : 0
    const maxValueY = max(dataPoints, getValueY) ?? 0
    const scaleX = scaleTime({
      range: [margin.left, innerWidth + margin.left],
      domain: extent(dataPoints, getValueX) as [Date, Date]
    })
    const scaleY = scaleLinear({
      range: [innerHeight + margin.top, margin.top],
      domain: [0, (maxValueY + 1) + maxValueY / 5],
      nice: true
    })

    // Right Axis (if auxData is present)
    const auxDt = auxData ?? []
    const auxDataPoints = auxDt.sort((a, b) => (a.x > b.x ? 1 : -1))
    const auxMaxValueY = max(auxDataPoints, getValueY) ?? 0
    const auxScaleX = scaleTime({
      range: [margin.left, innerWidth + margin.left],
      domain: extent(auxDataPoints, getValueX) as [Date, Date]
    })
    const auxScaleY = scaleLinear({
      range: [innerHeight + margin.top, margin.top],
      domain: [0, (auxMaxValueY + 1) + auxMaxValueY / 5],
      nice: true
    })

    const handleTooltip = useCallback(
      (
        event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>
      ) => {
        if (!svgRef.current) return

        const { x } = localPoint(svgRef.current, event) ?? { x: 0 }
        const x0 = scaleX.invert(x)
        // orig data
        const index = bisectDate(dataPoints, x0, 1)
        const d0 = dataPoints[index - 1]
        const d1 = dataPoints[index]
        let d = d0

        if (d1 && getValueX(d1)) {
          d =
            x0.valueOf() - getValueX(d0).valueOf() >
            getValueX(d1).valueOf() - x0.valueOf()
              ? d1
              : d0
        }

        // orig data
        const auxIndex = bisectDate(auxDataPoints, x0, 1)
        const auxD0 = auxDataPoints[auxIndex - 1]
        const auxD1 = auxDataPoints[auxIndex]
        let auxD = auxD0

        if (auxD1 && getValueX(auxD1)) {
          auxD =
            x0.valueOf() - getValueX(auxD0).valueOf() >
            getValueX(auxD1).valueOf() - x0.valueOf()
              ? auxD1
              : auxD0
        }

        showTooltip({
          tooltipData: {
            data: d,
            auxData: auxD,
            circles: {
              c1: {
                left: scaleX(getValueX(d)),
                top: scaleY(getValueY(d))
              },
              c2: {
                left: auxScaleX(getValueX(auxD)),
                top: auxScaleY(getValueY(auxD))
              }
            }
          },
          tooltipLeft: Math.min(scaleX(getValueX(d)), auxScaleX(getValueX(auxD))),
          tooltipTop: Math.min(scaleY(getValueY(d)), auxScaleY(getValueY(auxD)))
        })
      },
      [showTooltip, scaleY, scaleX]
    )

    return (
      <div>
        <svg ref={svgRef} width={width} height={height}>
          {/* Background */}
          <LinearGradient
            id={backgroundGradientId}
            from={backgroundColor?.primary}
            to={backgroundColor?.secondary}
            rotate="-90"
            opacity={0.5}
          />
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            style={{
              fill: `url(#${backgroundGradientId})`
            }}
          />

          {/* Axis */}
          {!withoutYAxis && (
            <AxisLeft
              scale={scaleY}
              left={margin.left}
              stroke={axisColor}
              strokeWidth={1}
              tickStroke="transparent"
              tickLength={3}
              tickLabelProps={() => ({
                fill: axisColor,
                textAnchor: 'end',
                verticalAnchor: 'middle',
                fontSize: axisFontSize,
                fontWeight: 200
              })}
            />
          )}

          {/* Axis Right */}
          {!withoutYAxis && auxData && (
            <AxisRight
              scale={auxScaleY}
              left={width - margin.right}
              stroke={axisColor}
              strokeWidth={1}
              tickStroke="transparent"
              tickLength={3}
              tickLabelProps={() => ({
                fill: axisColor,
                textAnchor: 'start',
                verticalAnchor: 'middle',
                fontSize: axisFontSize,
                fontWeight: 200
              })}
            />
          )}

          {!withoutXAxis && (
            <AxisBottom
              scale={scaleX}
              top={innerHeight + margin.top}
              stroke={axisColor}
              strokeWidth={1}
              tickStroke={axisColor}
              tickLength={3}
              numTicks={width > 520 ? 10 : 5}
              tickLabelProps={() => ({
                fill: axisColor,
                textAnchor: 'middle',
                verticalAnchor: 'middle',
                fontSize: axisFontSize,
                fontWeight: 200
              })}
            />
          )}

          {/* Title */}
          {!withoutTitle && (
            <>
              {group && (
                <g>
                  {group.length > 20 && <title>{group}</title>}
                  <Text
                    verticalAnchor="start"
                    fontSize={`${containerSize / 48}px`}
                    style={{
                      fill: titleColor
                    }}
                    x={margin.left + 10}
                    y={20}
                  >
                    {truncateWithEllipsis(group, 20)}
                  </Text>
                </g>
              )}
              <g>
                {title.length > 15 && <title>{title}</title>}
                <Text
                  verticalAnchor="start"
                  fontSize={`${containerSize / 36}px`}
                  fontWeight={500}
                  style={{
                    fill: titleColor
                  }}
                  x={margin.left + 10}
                  y={group ? 20 + (containerSize / 30) : 20}
                >
                  {truncateWithEllipsis(title, 15)}
                </Text>
              </g>
            </>
          )}

          {/* Recent value */}
          {!withoutRecentValue && (
            <Text
              textAnchor="end"
              verticalAnchor="start"
              fontSize={`${containerSize / 15}px`}
              fontWeight={500}
              style={{
                fill: textColor
              }}
              x={(width - margin.left) - 20}
              y={23}
            >
              {round(recentValue)}
            </Text>
          )}

          {/* Line */}
          <LinearGradient
            id={linePathGradientId}
            from={areaColor?.primary}
            to={areaColor?.secondary}
          />
          <LinePath
            data={dataPoints}
            x={(d) => scaleX(getValueX(d)) ?? 0}
            y={(d) => scaleY(getValueY(d)) ?? 0}
            stroke={`url(#${linePathGradientId})`}
            strokeWidth={3}
            curve={curve}
            markerMid={`url(#${markerCircleId})`}
          />

          {/* Area */}
          <LinearGradient
            id={areaGradientId}
            from={areaColor?.primary}
            to={areaColor?.secondary}
            toOpacity={0.1}
          />
          <AreaClosed<DataPoint>
            data={dataPoints}
            x={(d) => scaleX(getValueX(d)) ?? 0}
            y={(d) => scaleY(getValueY(d)) ?? 0}
            yScale={scaleY}
            fill={`url(#${areaGradientId})`}
            curve={curve}
          />

          {auxData && (
            <>
              <LinearGradient
                id={auxLinePathGradientId}
                from={auxColor?.primary}
                to={auxColor?.secondary}
              />
              <LinePath
                data={auxDataPoints}
                x={(d) => auxScaleX(getValueX(d)) ?? 0}
                y={(d) => auxScaleY(getValueY(d)) ?? 0}
                stroke={`url(#${auxLinePathGradientId})`}
                strokeWidth={3}
                curve={curves.linear}
                markerMid={`url(#${auxMarkerCircleId})`}
              />
            </>
          )}

          {/* Marker */}
          {withoutTooltip && (
            <MarkerCircle
              id={markerCircleId}
              fill={markerColor}
              size={2}
              stroke={markerStrokeColor}
              strokeWidth={1}
              refX={2}
            />
          )}
          {withoutTooltip && auxData && (
            <MarkerCircle
              id={auxMarkerCircleId}
              fill={markerColor}
              size={2}
              stroke={markerStrokeColor}
              strokeWidth={1}
              refX={2}
            />
          )}

          {/* Tooltip area */}
          {!withoutTooltip && (
            <Bar
              x={margin.left}
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={hideTooltip}
            />
          )}

          {/* Tooltip imaginary line */}
          {tooltipData && (
            <g>
              <Line
                from={{
                  x: tooltipLeft,
                  y: tooltipTop + 1
                }}
                to={{
                  x: tooltipLeft,
                  y: innerHeight + margin.top
                }}
                stroke={areaColor?.primary}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="2,5"
              />
              <circle
                cx={tooltipData.circles.c1.left}
                cy={tooltipData.circles.c1.top + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipData.circles.c1.left}
                cy={tooltipData.circles.c1.top}
                r={7}
                fill={markerColor}
                stroke={markerStrokeColor}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipData.circles.c2.left}
                cy={tooltipData.circles.c2.top + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipData.circles.c2.left}
                cy={tooltipData.circles.c2.top}
                r={7}
                fill={markerColor}
                stroke={markerStrokeColor}
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>

        {/* Tooltip data */}
        {!withoutTooltip && tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={{
                ...tooltipStyles,
                background: backgroundColor?.primary,
                border: `1px solid ${areaColor?.primary}`,
                color: areaColor?.primary,
                fontSize: '.8rem',
                opacity: 0.8
              }}
            >
              <Stack spacing={0}>
                <Group position="apart">
                  <MText w={100}>Fuel Level:</MText>
                  <MText weight={500}>{`${tooltipData.data.y} L`}</MText>
                </Group>
                <Group position="apart">
                  <MText w={100}>Position Speed:</MText>
                  <MText weight={500}>{`${tooltipData.auxData.y} km/h`}</MText>
                </Group>
              </Stack>
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + 20}
              left={tooltipLeft}
              style={{
                ...tooltipStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
                fontSize: '.7rem'
              }}
            >
              {formatDateTime(getValueX(tooltipData.data))}
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
))
