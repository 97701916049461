import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMantineTheme, Box, Group, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import Selector, {
  getNoOfRecordOnMinutesPerBucketKey,
  type ChosenBucket,
  type ItemKeys
} from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'
import AreaWithAux from 'src/Charts/AreaWithAux'

export interface Props {
  agentId: Agent['agentId']
  sensorInstanceIds?: Array<Sensor['sensorInstanceId']>
  didChangeHeight?: (height: number, key: string) => void
}

export default function TankHistory ({
  agentId,
  sensorInstanceIds
}: Props) {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const allSensorsData = useApi(getSensorData)

  const { colors } = useMantineTheme()
  const { ref, width, height } = useElementSize()

  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '12 hours',
    data: {
      timeBucket: '1 minutes',
      noOfRecords: getNoOfRecordOnMinutesPerBucketKey('12 hours')
    }
  })

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  const sensors = useMemo(() => {
    if (!agent) return []
    return sensorInstanceIds
      ? agent.sensors.filter(({ sensorInstanceId }) => sensorInstanceIds.includes(sensorInstanceId)) ?? []
      : []
  }, [sensorInstanceIds, agent])

  const { fuelData, speedData } = allSensorsData.data.mapOrDefault(({ result }) => (
    {
      fuelData: result[0].data.map(({ x, y }) => ({
        x,
        y: typeof y !== 'undefined'
          ? y
          : 0
      })),
      speedData: result[1].data.map(({ x, y }) => ({
        x,
        y: typeof y !== 'undefined'
          ? y
          : 0
      }))
    }
  ), { fuelData: [], speedData: [] })

  const isLoading = agentDetails.loading || allSensorsData.loading

  const updateBucket = useCallback((input: ChosenBucket<ItemKeys>) => {
    setBucket(input)
  }, [setBucket])

  const loadHistory = () => {
    if (!sensors.length) return

    const time = bucket.item.split(' ')
    const isBelow24Hrs = bucket.item.includes('hour')
    const req = {
      sensorInstanceIds: sensors.map(({ sensorInstanceId }) => sensorInstanceId),
      startTime: dayjs().subtract(Number.parseInt(time[0]), time[1] as ManipulateType).format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: isBelow24Hrs
        ? '1 minutes'
        : bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: isBelow24Hrs
        ? getNoOfRecordOnMinutesPerBucketKey(bucket.item)
        : bucket.data.noOfRecords
    }

    void allSensorsData.fetch(req, token)
  }

  useEffect(() => {
    loadHistory()
  }, [bucket, sensors])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return sensors.length > 0 && (
    <Box
      ref={ref}
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <Group mb="sm" position="apart" noWrap>
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          Tank History
        </Text>
        <Selector chosenBucket={bucket} updateBucket={updateBucket} />
      </Group>
      {isLoading && <Loader />}
      {!isLoading && agent && sensors && (
        <AreaWithAux
          id={`${agent.agentName} - ${sensors[0].name}`}
          title="Tank History"
          data={fuelData}
          auxData={speedData}
          styles={{
            width,
            height: height - 55,
            margin: {
              top: 20,
              right: 60,
              bottom: 30,
              left: 70
            },
            theme: {
              titleColor: colors.primary[7],
              areaColor: {
                primary: colors.primary[4],
                secondary: colors.primary[7]
              },
              backgroundColor: {
                primary: colors.white[0],
                secondary: colors.primary[1]
              },
              axisColor: colors.primary[5],
              auxColor: {
                primary: colors.dark[3],
                secondary: colors.dark[7]
              }
            },
            curveType: 'linear',
            withoutTitle: true,
            withoutRecentValue: true
          }}
        />
      )}
    </Box>
  )
}
