import {
  Box,
  Group,
  Paper,
  Text,
  createStyles
} from '@mantine/core'
import { type Sensor, type LiquidType } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { usePaginatedApi } from 'src/utils/useApi'
import { type Alarm, getAlarmsForSensorInstance } from '@venturi-io/api/src/analytics/alarm'
import { useEffect } from 'react'
import { useUser } from 'src/UserContext'
import { hasAlarm } from 'src/AssetTemplate/shared'
import FuelTank from './FuelTank'
import AlarmIndicator from './AlarmIndicator'

const useStyles = createStyles(theme => ({
  titleContainer: {
    position: 'relative'
  },
  indicatorLine: {
    height: 32,
    width: 4
  },
  alarmIndicator: {
    position: 'absolute',
    top: -6,
    left: -16
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  }
}))

interface Props {
  fuelLevel: Sensor | null
  fuelCapacity: Sensor | null
  fuelConsumption: Sensor | null
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelCapacityLiter: number
  fuelLevelLiter: Sensor | null
  currFuelLevelLiter: number
  fuelType?: LiquidType
  setAlarms: (value: Alarm[] | null) => void
}

export default function TankStatus ({
  fuelLevel,
  fuelCapacity,
  fuelConsumption,
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelLevelLiter,
  fuelCapacityLiter,
  fuelType,
  currFuelLevelLiter,
  setAlarms
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const getFuelLevelLiterAlarms = usePaginatedApi(getAlarmsForSensorInstance)
  const fuelLevelLiterAlarms = getFuelLevelLiterAlarms.data.mapOrDefault(({ items }) => items, [])
  const sortedAlarms = fuelLevelLiterAlarms.sort((a, b) => b.setPoint - a.setPoint)

  const withAlarm = hasAlarm([
    fuelLevel,
    fuelCapacity,
    fuelConsumption,
    fuelLevelLiter
  ])

  const isCriticalAlarm = withAlarm && sortedAlarms.slice(-1)[0].setPoint >= currFuelLevelLiter

  useEffect(() => {
    if (fuelLevelLiter) {
      void getFuelLevelLiterAlarms.fetch({
        sensorInstanceId: fuelLevelLiter.sensorInstanceId,
        page: getFuelLevelLiterAlarms.page ?? 1,
        size: 10
      }, token)
    }
  }, [fuelLevelLiter, getFuelLevelLiterAlarms.page])

  useEffect(() => {
    setAlarms(fuelLevelLiterAlarms)
  }, [getFuelLevelLiterAlarms.data])

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
          color="#212121"
        >
          Tank Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicator}
            position="center"
            align="center"
            spacing={110}
          >
            <span
              className={classes.indicatorLine}
              style={{
                background: isCriticalAlarm
                  ? '#E03131'
                  : '#FFE066'
              }}
            />
            <AlarmIndicator
              size={32}
              alarmColor={isCriticalAlarm
                ? 'red'
                : 'yellow'}
            />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <FuelTank
          fuelLevelPercent={fuelLevelPercent}
          fuelRemainingPercent={fuelRemainingPercent}
          fuelLevelLiterAlarms={fuelLevelLiterAlarms}
          fuelCapacityLiter={fuelCapacityLiter}
          currFuelLevelLiter={currFuelLevelLiter}
          fuelType={fuelType}
          withAlarm={withAlarm}
          isCriticalAlarm={isCriticalAlarm}
        />
      </Box>
    </Paper>
  )
}
