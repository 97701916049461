import { createStyles, Group, Paper } from '@mantine/core'
import { IconEyeOff, IconPolygon } from '@tabler/icons-react'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'
import TextTooltip from 'src/Layout/TextTooltip'

const useStyles = createStyles(theme => ({
  container: {
    cursor: 'pointer',
    background: 'white',
    '&:hover': {
      background: theme.colors.gray[0]
    },
    overflowX: 'hidden'
  }
}))

interface Props {
  geoZone: GeoZone
  onSelectGeoZone: (geoZone: GeoZone) => void
}

export default function Item ({ geoZone, onSelectGeoZone }: Props) {
  const { classes } = useStyles()
  const {
    geoZoneId,
    name,
    hiddenOnMap
  } = geoZone

  return (
    <Paper
      key={geoZoneId}
      className={classes.container}
      p={16}
      shadow="xs"
      radius={8}
      onClick={() => onSelectGeoZone(geoZone)}
    >
      <Group position="apart" align="center">
        <Group spacing={8}>
          <IconPolygon
            size={20}
            stroke={1.5}
            color={hiddenOnMap
              ? 'gray'
              : '#2998F0'}
          />
          <TextTooltip
            value={name}
            size={12}
            maxLength={30}
          />
        </Group>
        {hiddenOnMap && (
          <IconEyeOff
            size={18}
            stroke={1.5}
            color="silver"
          />
        )}
      </Group>
    </Paper>
  )
}
