import { type MouseEvent, useCallback, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Collapse,
  Paper,
  Stack,
  Tooltip
} from '@mantine/core'
import {
  IconCircle,
  IconEraser,
  IconRectangle,
  IconShape,
  IconSquare,
  IconSquareRoundedX,
  IconSquareRoundedPlus
} from '@tabler/icons-react'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { MODES } from './constants'
import { type Feature, type MapMode } from './shared'

const useStyles = createStyles(() => ({
  container: {
    height: 'auto',
    width: 40,
    position: 'absolute',
    top: 232,
    right: 0,
    padding: 8,
    borderRadius: '8px 0 0 8px'
  }
}))

interface Props {
  mode: MapMode
  activeFeature?: Feature
  switchMode: (value: string) => void
  showDiscard?: boolean
  onDiscard: () => void
}

export default function Toolbar ({
  mode,
  switchMode,
  showDiscard = false,
  onDiscard
}: Props) {
  const [showTools, setShowTools] = useState(false)
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false)
  const { classes } = useStyles()

  const handleDelete = useCallback(() => {
    onDiscard()
    setShowDiscardConfirmation(false)
  }, [onDiscard])

  const isActiveMode = (toMode: string) => mode?.id === toMode
  const activeColor = (flag: boolean) => flag
    ? 'primary'
    : 'gray'

  return (
    <>
      <Paper className={classes.container} shadow="lg">
        <Stack spacing={6} align="center">
          <Tooltip
            label={`${showTools ? 'Hide' : 'Show'} Tools`}
            color="primary"
            position="left"
            withArrow
          >
            <ActionIcon onClick={() => setShowTools(!showTools)}>
              {showTools
                ? (
                  <IconSquareRoundedX
                    size={24}
                    stroke={1.5}
                    color="gray"
                  />
                  )
                : (
                  <IconSquareRoundedPlus
                    size={24}
                    stroke={1.5}
                    color="gray"
                  />
                  )}
            </ActionIcon>
          </Tooltip>
          <Collapse in={showTools}>
            <Stack spacing={6} align="center">
              <Tooltip
                label="Draw Polygon"
                color="primary"
                position="left"
                withArrow
              >
                <ActionIcon
                  disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_POLYGON)}
                  color={activeColor(isActiveMode(MODES.DRAW_POLYGON))}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(isActiveMode(MODES.DRAW_POLYGON)
                      ? MODES.VIEWING
                      : MODES.DRAW_POLYGON
                    )
                  }}
                >
                  <IconShape size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label="Draw Rectangle"
                color="primary"
                position="left"
                withArrow
              >
                <ActionIcon
                  disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_RECTANGLE)}
                  color={activeColor(isActiveMode(MODES.DRAW_RECTANGLE))}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(isActiveMode(MODES.DRAW_RECTANGLE)
                      ? MODES.VIEWING
                      : MODES.DRAW_RECTANGLE
                    )
                  }}
                >
                  <IconRectangle size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label="Draw Square"
                color="primary"
                position="left"
                withArrow
              >
                <ActionIcon
                  disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_SQUARE)}
                  color={activeColor(isActiveMode(MODES.DRAW_SQUARE))}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(isActiveMode(MODES.DRAW_SQUARE)
                      ? MODES.VIEWING
                      : MODES.DRAW_SQUARE
                    )
                  }}
                >
                  <IconSquare size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label="Draw Circle"
                color="primary"
                position="left"
                withArrow
              >
                <ActionIcon
                  disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_CIRCLE)}
                  color={activeColor(isActiveMode(MODES.DRAW_CIRCLE))}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(isActiveMode(MODES.DRAW_CIRCLE)
                      ? MODES.VIEWING
                      : MODES.DRAW_CIRCLE
                    )
                  }}
                >
                  <IconCircle size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              {showDiscard && (
                <Tooltip
                  label="Discard Unsaved Drawings"
                  color="primary"
                  position="left"
                  withArrow
                >
                  <ActionIcon onClick={() => setShowDiscardConfirmation(true)}>
                    <IconEraser size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Stack>
          </Collapse>
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showDiscardConfirmation}
        title="Discarding all unsaved geozones"
        confirmLabel="Discard"
        question="Are you sure you want to perform this action? This action can't be undone."
        onClose={() => setShowDiscardConfirmation(false)}
        onCancel={() => setShowDiscardConfirmation(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
