import { useCallback, useState, type MouseEvent } from 'react'
import {
  createStyles,
  ActionIcon,
  Box,
  Group,
  Tabs
} from '@mantine/core'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'
import {
  IconChecklist,
  IconEdit,
  IconInfoSquareRounded,
  IconPolygon,
  IconGeometry
} from '@tabler/icons-react'
import area from '@turf/area'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Feature, type MapMode } from '../shared'
import { MODES } from '../constants'
import Properties from './Properties'
import AreaDetails from './AreaDetails'
import Rules from './Rules'

interface StyleParams {
  screenCoordX: number
  screenCoordY: number
}

const useStyles = createStyles((_, { screenCoordX, screenCoordY }: StyleParams) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    right: screenCoordX,
    top: screenCoordY,
    maxWidth: '300px',
    fontSize: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 17.22447395324707px 0px',
    zIndex: 10
  },
  header: {
    background: '#FFF',
    borderRadius: '8px 0 0 0',
    borderBottom: '.5px solid #E2E1E1',
    padding: '12px 16px',
    width: 300,
    height: 48
  },
  tab: {
    background: '#FFF',
    marginBottom: 1
  },
  tabPanel: {
    background: '#FFF',
    borderRadius: '0 0 0 8px',
    width: '300px',
    height: '325px',
    overflowY: 'auto',
    scrollbarWidth: 'thin'
  }
}))

const defaultScreenCoords = {
  screenCoordX: 0,
  screenCoordY: 44
}

export type GeoZoneData = Omit<GeoZone, 'boundary'>

interface Props {
  mode: MapMode
  orgId: number
  activeFeature: Feature
  selectedGeoZone: GeoZoneData | null
  switchMode: (value: string) => void
  onUpdate: (geoZone: GeoZone, newAgentGroupIds: number[], removedAgentGroupIds: number[]) => void
  onDelete: () => void
  onCancel: () => void
  setShowWizard: (value: boolean) => void
}

export default function ExistingGeoZoneWizard ({
  mode,
  orgId,
  activeFeature,
  selectedGeoZone,
  switchMode,
  onUpdate,
  onDelete,
  onCancel,
  setShowWizard
}: Props) {
  const { classes } = useStyles(defaultScreenCoords)
  const featureArea = activeFeature && area(activeFeature)
  const [hasExistingRule, setHasExistingRule] = useState(false)

  const handleEdit = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    switchMode(mode?.id === MODES.EDITING
      ? MODES.VIEWING
      : MODES.EDITING
    )
  }, [mode, MODES, switchMode])

  return (
    <Box className={classes.container}>
      <Group className={classes.header} align="flex-start" position="apart">
        <Group spacing={4}>
          <IconPolygon size={20} color="gray" />
          <TextTooltip
            size={16}
            weight={700}
            color="gray.8"
            value={selectedGeoZone?.name ?? ''}
            maxLength={20}
          />
        </Group>
        {mode?.id !== MODES.EDITING && (
          <ActionIcon
            variant="subtle"
            color="gray.6"
            onClick={handleEdit}
          >
            <IconEdit size={20} stroke={1.5} />
          </ActionIcon>
        )}
      </Group>
      <Tabs
        defaultValue="properties"
        variant="outline"
        orientation="vertical"
        color="primary"
        radius="md"
      >
        <Tabs.List>
          <Tabs.Tab
            className={classes.tab}
            value="properties"
            icon={(
              <IconInfoSquareRounded
                size={20}
                stroke={1.5}
                color="gray"
              />
            )}
          >
          </Tabs.Tab>
          <Tabs.Tab
            className={classes.tab}
            value="rules"
            icon={(
              <IconChecklist
                size={20}
                stroke={1.5}
                color="gray"
              />
            )}
          >
          </Tabs.Tab>
          <Tabs.Tab
            className={classes.tab}
            value="area"
            icon={(
              <IconGeometry
                size={20}
                stroke={1.5}
                color="gray"
              />
            )}
          >
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel className={classes.tabPanel} value="properties">
          <Properties
            mode={mode}
            orgId={orgId}
            activeFeature={activeFeature}
            selectedGeoZone={selectedGeoZone}
            hasExistingRule={hasExistingRule}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={onCancel}
            setShowWizard={setShowWizard}
          />
        </Tabs.Panel>
        <Tabs.Panel className={classes.tabPanel} value="rules">
          {selectedGeoZone !== null && (
            <Rules
              mode={mode}
              selectedGeoZone={selectedGeoZone}
              setHasExistingRule={setHasExistingRule}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel className={classes.tabPanel} value="area">
          <AreaDetails featureArea={featureArea} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  )
}
